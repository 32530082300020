/* global console, document, Excel, Office, OfficeRuntime, setTimeout */

// The initialize function must be run each time a new page is loaded
document.addEventListener("DOMContentLoaded", async () => {
  Office.onReady(async () => {
    console.log("ready");

    // initial login if key available, else show login
    if (await getItem("key")) {
      document.getElementById("get-started-section").style.display = "none";
      initAuth();
    } else {
      document.getElementById("loadingOverlay").style.display = "none";
    }

    // get/set date format
    var dateFormat = await getItem("date_format");
    if (dateFormat) {
      document.querySelector(`#inputDateFormat [value="${dateFormat}"]`).selected = true;
    } else {
      await setItem("date_format", "YYYY-MM-DD");
    }
    // get/set weight unit
    var weight_unit = await getItem("weight_unit");
    if (weight_unit) {
      document.querySelector(`#inputWeightUnit [value="${weight_unit}"]`).selected = true;
    } else {
      await setItem("weight_unit", "kg");
    }

    // auth click events
    document.getElementById("get-started-btn").addEventListener("click", () => {
      document.getElementById("get-started-section").style.display = "none";
      document.getElementById("auth").hidden = false;
    });
    document.getElementById("authKeyButton").addEventListener("click", () => authenticate("key"));
    document.getElementById("authLoginButton").addEventListener("click", () => authenticate("credentials"));
    document.getElementById("show-credentials-btn").addEventListener("click", () => toggleAuth("credentials"));
    document.getElementById("show-key-btn").addEventListener("click", () => toggleAuth("key"));
    document.getElementById("inputKey").addEventListener("keypress", function (event) {
      if (event.key === "Enter") authenticate("key");
    });
    document.getElementById("inputPassword").addEventListener("keypress", function (event) {
      if (event.key === "Enter") authenticate("credentials");
    });

    // menu click events
    document.getElementById("home-btn").addEventListener("click", () => changeSection("home"));
    document.getElementById("trackings-btn").addEventListener("click", () => changeSection("trackings"));
    //document.getElementById('products-btn').addEventListener('click', () => changeSection('products'))
    document.getElementById("customers-btn").addEventListener("click", () => changeSection("customers"));
    document.getElementById("hubs-btn").addEventListener("click", () => changeSection("hubs"));
    document.getElementById("couriers-btn").addEventListener("click", () => changeSection("couriers"));
    document.getElementById("settings-btn").addEventListener("click", () => changeSection("settings"));
    document.getElementById("logout-btn").onclick = logout;

    // home page click events
    document.getElementById("refresh-btn").onclick = refresh;
    document.getElementById("refresh-capacity").onclick = refreshCapacity;

    // trackings page click events
    document.getElementById("back-trackings").addEventListener("click", () => changeSection("trackings"));
    document.getElementById("back-trackings-1").addEventListener("click", () => changeSection("trackings"));
    document.getElementById("back-trackings-2").addEventListener("click", () => changeSection("trackings"));
    document.getElementById("tracking-button").onclick = addTracking;
    document.getElementById("tracking-button1").onclick = updateTracking;
    document.getElementById("tracking-button2").onclick = deleteTracking;

    // products page click events
    document.getElementById("back-products").addEventListener("click", () => changeSection("products"));
    document.getElementById("back-products-1").addEventListener("click", () => changeSection("products"));
    document.getElementById("back-products-2").addEventListener("click", () => changeSection("products"));
    document.getElementById("product-button").onclick = addProduct;
    document.getElementById("product-button1").onclick = updateProduct;
    document.getElementById("product-button2").onclick = deleteProduct;

    // customers page click events
    document.getElementById("back-customers").addEventListener("click", () => changeSection("customers"));
    document.getElementById("back-customers-1").addEventListener("click", () => changeSection("customers"));
    document.getElementById("back-customers-2").addEventListener("click", () => changeSection("customers"));
    document.getElementById("customer-button").onclick = addCustomer;
    document.getElementById("customer-button1").onclick = updateCustomer;
    document.getElementById("customer-button2").onclick = deleteCustomer;

    // hubs page click events
    document.getElementById("back-hubs").addEventListener("click", () => changeSection("hubs"));
    document.getElementById("back-hubs-1").addEventListener("click", () => changeSection("hubs"));
    document.getElementById("back-hubs-2").addEventListener("click", () => changeSection("hubs"));
    document.getElementById("hub-button").onclick = addHub;
    document.getElementById("hub-button1").onclick = updateHub;
    document.getElementById("hub-button2").onclick = deleteHub;

    // settings events
    document.getElementById("save-settings-button").onclick = saveSettings;
  });

  async function refreshCapacity() {
    const span = document.getElementById("capacity-span");
    const icon = document.getElementById("refresh-capacity-icon");
    const spinner = document.getElementById("refresh-capacity-spinner");

    if (icon.hidden) return;

    const keyInStorage = await getItem("key");
    if (!keyInStorage) return null;

    icon.hidden = true;
    spinner.hidden = false;

    try {
      const response = await fetch("https://api.globalpackagetracker.com/user/capacity", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          key: keyInStorage,
        },
      });
      const data = await response.json();
      if (response.ok) {
        span.textContent = data.capacity;
      } else {
        throw new Error("Authentication failed.");
      }
    } catch (e) {
      console.error(e);
    } finally {
      spinner.hidden = true;
      icon.hidden = false;
    }
  }

  function refresh() {
    document.getElementById("refresh-btn").disabled = true;
    document.getElementById("refresh-spinner").hidden = false;
    Excel.run(async (context) => {
      const delay = 1000; //100
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      let range = sheet.getUsedRange();
      range.load("formulas");
      await context.sync();

      var counter = 0;
      for (let rowIndex = 0; rowIndex < range.formulas.length; rowIndex++) {
        const row = range.formulas[rowIndex];
        for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
          const cell = row[columnIndex];
          if (String(cell).toLowerCase().startsWith("=get") || String(cell).toLowerCase().startsWith("=gpt")) {
            counter++;
            const cellObj = range.getCell(rowIndex, columnIndex);
            cellObj.calculate();
            await context.sync();
            if (counter % 20 === 0) {
              await new Promise((resolve) => setTimeout(resolve, 3000));
            }
          }
        }
      }
      document.getElementById("refresh-spinner").hidden = true;
      document.getElementById("refresh-btn").disabled = false;
    });
  }

  async function initAuth() {
    console.log("init auth");
    try {
      const keyInStorage = await getItem("key");
      if (!keyInStorage) throw Error("could not load key from storage");

      const url = "https://api.globalpackagetracker.com/user/authByKey";
      await sendAuth({ key: keyInStorage }, url);
      document.getElementById("loadingOverlay").style.display = "none";
      document.getElementById("auth").hidden = true;
      document.getElementById("main").hidden = false;
      refreshCapacity();
    } catch (e) {
      console.error(e);
      await removeItem("key");
      location.reload();
    }
  }
  async function authenticate(method) {
    console.log("authenticate", method);
    const inputKey = document.getElementById("inputKey");
    const keySpinner = document.getElementById("keySpinner");

    const authKeyButton = document.getElementById("authKeyButton");
    const authLoginButton = document.getElementById("authLoginButton");

    const inputEmail = document.getElementById("inputEmail");
    const inputPassword = document.getElementById("inputPassword");
    const credentialsSpinner = document.getElementById("credentialsSpinner");

    const authKeyFailed = document.getElementById("authKeyFailed");
    const authCredentialsFailed = document.getElementById("authCredentialsFailed");
    authKeyFailed.hidden = true;
    authCredentialsFailed.hidden = true;

    let url = "https://api.globalpackagetracker.com/user/";
    let body = {};

    if (method === "key") {
      inputKey.classList.remove("is-invalid");
      if (!inputKey.value) return inputKey.classList.add("is-invalid");
      inputKey.classList.remove("is-invalid");

      keySpinner.hidden = false;
      authKeyButton.disabled = true;

      body.key = inputKey.value;
      url += "authByKey";

      try {
        const key = await sendAuth(body, url);
        const keyStored = await setItem("key", key);
        if (!keyStored) throw Error("Could not save key storage");
        document.getElementById("auth").hidden = true;
        document.getElementById("main").hidden = false;
        refreshCapacity();
      } catch (e) {
        inputKey.classList.add("is-invalid");
        authKeyFailed.innerHTML = e.message;
        return (authKeyFailed.hidden = false);
      } finally {
        keySpinner.hidden = true;
        authKeyButton.disabled = false;
      }
    } else if (method === "credentials") {
      inputEmail.classList.remove("is-invalid");
      inputPassword.classList.remove("is-invalid");
      if (!inputEmail.value && !inputPassword.value) {
        inputEmail.classList.add("is-invalid");
        inputPassword.classList.add("is-invalid");
        return;
      }
      if (!inputEmail.value) return inputEmail.classList.add("is-invalid");
      if (!inputPassword.value) return inputPassword.classList.add("is-invalid");
      inputEmail.classList.remove("is-invalid");
      inputPassword.classList.remove("is-invalid");

      credentialsSpinner.hidden = false;
      authLoginButton.disabled = true;

      body.email = inputEmail.value;
      body.password = inputPassword.value;
      url += "authByCredentials";

      try {
        const key = await sendAuth(body, url);
        const keyStored = await setItem("key", key);
        if (!keyStored) throw Error("Could not save key storage");
        document.getElementById("auth").hidden = true;
        document.getElementById("main").hidden = false;
        refreshCapacity();
      } catch (e) {
        inputEmail.classList.add("is-invalid");
        inputPassword.classList.add("is-invalid");
        authCredentialsFailed.innerHTML = e.message;
        return (authCredentialsFailed.hidden = false);
      } finally {
        credentialsSpinner.hidden = true;
        authLoginButton.disabled = false;
      }
    }
  }
  function toggleAuth(method) {
    if (method === "key") {
      authByLogin.hidden = true;
      authByKey.hidden = false;
    } else if (method === "credentials") {
      authByKey.hidden = true;
      authByLogin.hidden = false;
    }
  }
  async function logout() {
    await removeItem("key");
    location.reload();
  }
  async function sendAuth(body, url) {
    console.log(body);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (data.status == 200) {
        return data.key;
      } else {
        throw new Error("Authentication failed.");
      }
    } catch (e) {
      throw new Error(e.message);
    }
  }

  // function needs to be copied to taskpane.html so edit/delete buttons will work
  function changeSection(section, doc = {}) {
    const sections = document.getElementsByTagName("section");
    for (let i = 0; i < sections.length; i++) {
      if (sections[i].id === section) {
        sections[i].hidden = false;
      } else {
        sections[i].hidden = true;
      }
    }

    switch (section) {
      case "settings":
        clearForm("settings");
        break;
      case "trackings":
        loadTrackings();
        break;
      case "add-tracking":
        document.getElementById("add-tracking").scrollTo(0, 0);
        clearForm("add-tracking");
        enableDselect("inputCourier", "couriers", "dhl");
        enableDselect("inputCustomer", "customers");
        enableDselect("inputShippedBy", "own-hubs");
        enableDselect("inputShippedTo", "customer-hubs");
        enableDselect("inputProduct", "products");
        break;
      case "edit-tracking":
        document.getElementById("edit-tracking").scrollTo(0, 0);
        if (doc.customer) {
          enableDselect("inputCustomer1", "customers", doc.customer.customer_id);
        } else {
          enableDselect("inputCustomer1", "customers");
        }
        if (doc.shippedBy) {
          enableDselect("inputShippedBy1", "own-hubs", doc.shippedBy.hub_id);
        } else {
          enableDselect("inputShippedBy1", "own-hubs");
        }
        if (doc.shippedTo) {
          enableDselect("inputShippedTo1", "customer-hubs", doc.shippedTo.hub_id);
        } else {
          enableDselect("inputShippedTo1", "customer-hubs");
        }
        if (doc.product) {
          enableDselect("inputProduct1", "products", doc.product.product_id);
        } else {
          enableDselect("inputProduct1", "products");
        }
        document.getElementById("inputTrackingNumber1").value = doc.tracking_number;
        document.getElementById("inputCourier1").value = doc.courier_code;
        document.getElementById("inputTrackingWeight1").value = doc.weight;
        document.getElementById("inputTrackingCosts1").value = doc.costs;
        document.getElementById("tracking-button1").disabled = false;
        document.getElementById("tracking-success1").hidden = true;
        document.getElementById("tracking-failed1").hidden = true;
        break;
      case "delete-tracking":
        document.getElementById("tracking-number-delete").textContent = doc.tracking_number;
        document.getElementById("courier-code-delete").textContent = doc.courier_code;
        document.getElementById("tracking-button2").disabled = false;
        document.getElementById("tracking-success2").hidden = true;
        document.getElementById("tracking-failed2").hidden = true;
        break;
      case "products":
        loadProducts();
        break;
      case "add-product":
        clearForm("add-product");
        break;
      case "edit-product":
        document.getElementById("inputProductId").value = doc.product_id;
        document.getElementById("inputProductName1").value = doc.product_name;
        document.getElementById("inputProductWeight1").value = doc.product_weight;
        document.getElementById("inputProductCosts1").value = doc.product_costs;
        document.getElementById("product-button1").disabled = false;
        document.getElementById("product-success1").hidden = true;
        document.getElementById("product-failed1").hidden = true;
        break;
      case "delete-product":
        document.getElementById("product-id-delete").textContent = doc.product_id;
        document.getElementById("product-button2").disabled = false;
        document.getElementById("product-success2").hidden = true;
        document.getElementById("product-failed2").hidden = true;
        break;
      case "customers":
        loadCustomers();
        break;
      case "add-customer":
        clearForm("add-customer");
        break;
      case "edit-customer":
        document.getElementById("inputCustomerId").value = doc.customer_id;
        document.getElementById("inputCustomerName1").value = doc.customer_name;
        document.getElementById("inputCustomerEmail1").value = doc.customer_email;
        document.getElementById("inputCustomerPhone1").value = doc.customer_phone;
        document.getElementById("customer-button1").disabled = false;
        document.getElementById("customer-success1").hidden = true;
        document.getElementById("customer-failed1").hidden = true;
        break;
      case "delete-customer":
        document.getElementById("customer-id-delete").textContent = doc.customer_id;
        document.getElementById("customer-button2").disabled = false;
        document.getElementById("customer-success2").hidden = true;
        document.getElementById("customer-failed2").hidden = true;
        break;
      case "hubs":
        loadHubs();
        break;
      case "add-hub":
        clearForm("add-hub");
        enableDselect("inputHubOwner", "customer-as-hub-owner");
        break;
      case "edit-hub":
        if (doc.hub_owner) {
          enableDselect("inputHubOwner1", "customer-as-hub-owner", doc.hub_owner.customer_id);
        } else {
          enableDselect("inputHubOwner1", "customer-as-hub-owner");
        }
        document.getElementById("inputHubId").value = doc.hub_id;
        document.getElementById("inputHubName1").value = doc.hub_name;
        document.getElementById("inputHubAddress1").value = doc.hub_address;
        document.getElementById("inputHubEmail1").value = doc.hub_email;
        document.getElementById("inputHubPhone1").value = doc.hub_phone;
        document.getElementById("hub-button1").disabled = false;
        document.getElementById("hub-success1").hidden = true;
        document.getElementById("hub-failed1").hidden = true;
        break;
      case "delete-hub":
        document.getElementById("hub-id-delete").textContent = doc.hub_id;
        document.getElementById("hub-button2").disabled = false;
        document.getElementById("hub-success2").hidden = true;
        document.getElementById("hub-failed2").hidden = true;
        break;
      case "couriers":
        loadCouriers();
        break;
    }
  }
  function clearForm(form) {
    switch (form) {
      case "settings":
        document.getElementById("settings-success").hidden = true;
        break;
      case "add-tracking":
        document.getElementById("inputTrackingNumber").classList.remove("is-invalid");
        document.getElementById("inputTrackingWeight").classList.remove("is-invalid");
        document.getElementById("inputTrackingCosts").classList.remove("is-invalid");
        document.getElementById("tracking-success").hidden = true;
        document.getElementById("tracking-failed").hidden = true;
        break;
      case "edit-tracking":
        document.getElementById("inputTrackingWeight1").classList.remove("is-invalid");
        document.getElementById("inputTrackingCosts1").classList.remove("is-invalid");
        document.getElementById("tracking-success1").hidden = true;
        document.getElementById("tracking-failed1").hidden = true;
        break;
      case "add-product":
        document.getElementById("inputProductName").classList.remove("is-invalid");
        document.getElementById("inputProductWeight").classList.remove("is-invalid");
        document.getElementById("inputProductCosts").classList.remove("is-invalid");
        document.getElementById("product-success").hidden = true;
        document.getElementById("product-failed").hidden = true;
        break;
      case "edit-product":
        document.getElementById("inputProductName1").classList.remove("is-invalid");
        document.getElementById("inputProductWeight1").classList.remove("is-invalid");
        document.getElementById("inputProductCosts1").classList.remove("is-invalid");
        document.getElementById("product-success1").hidden = true;
        document.getElementById("product-failed1").hidden = true;
        break;
      case "add-customer":
        document.getElementById("inputCustomerName").classList.remove("is-invalid");
        document.getElementById("inputCustomerEmail").classList.remove("is-invalid");
        document.getElementById("inputCustomerPhone").classList.remove("is-invalid");
        document.getElementById("customer-success").hidden = true;
        document.getElementById("customer-failed").hidden = true;
        break;
      case "edit-customer":
        document.getElementById("inputCustomerName1").classList.remove("is-invalid");
        document.getElementById("inputCustomerEmail1").classList.remove("is-invalid");
        document.getElementById("inputCustomerPhone1").classList.remove("is-invalid");
        document.getElementById("customer-success1").hidden = true;
        document.getElementById("customer-failed1").hidden = true;
        break;
      case "add-hub":
        document.getElementById("inputHubName").classList.remove("is-invalid");
        document.getElementById("inputHubEmail").classList.remove("is-invalid");
        document.getElementById("inputHubPhone").classList.remove("is-invalid");
        document.getElementById("hub-success").hidden = true;
        document.getElementById("hub-failed").hidden = true;
        break;
      case "edit-hub":
        document.getElementById("inputHubName1").classList.remove("is-invalid");
        document.getElementById("inputHubEmail1").classList.remove("is-invalid");
        document.getElementById("inputHubPhone1").classList.remove("is-invalid");
        document.getElementById("hub-success1").hidden = true;
        document.getElementById("hub-failed1").hidden = true;
        break;
    }
  }

  async function loadTrackings() {
    await initTrackingsDaterange();
    const key = await getItem("key");
    if (!key) throw Error("Could not get key from storage");

    const daterangeItem = await getItem("trackings-daterange");
    if (!daterangeItem) throw Error("Could not get daterange from storage");
    const daterange = JSON.parse(daterangeItem);

    if ($.fn.DataTable.isDataTable("#trackings-table")) {
      let table = $("#trackings-table").DataTable();
      // Overwrite the data function with the new range
      table.settings()[0].ajax.data = function (data) {
        data.start = new Date(daterange.start).getTime();
        data.end = new Date(daterange.end).getTime();
      };
      $("#trackings-table").DataTable().ajax.reload();
      return;
    }
    const table = $("#trackings-table").DataTable({
      dom: '<"row gx-2"<"col"<"float-start"f>><"col-auto"B>>trip',
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search",
      },
      buttons: [
        {
          text: '<i class="bi bi-plus-lg bi-bold me-1"></i>Add tracking',
          className: "btn btn-dark",
          action: function (e, dt, node, config) {
            changeSection("add-tracking");
          },
        },
      ],
      ajax: {
        url: "https://api.globalpackagetracker.com/shipment/get",
        type: "GET",
        headers: {
          key,
        },
        data: function (data) {
          data.start = new Date(daterange.start).getTime();
          data.end = new Date(daterange.end).getTime();
        },
        dataSrc: function (response) {
          if (response.status !== 200) return [];
          response.documents.forEach((doc) => {
            doc.options = `<a class='btn btn-sm btn-warning me-3 fw-light rounded-0' onclick='changeSection(\"edit-tracking\",${JSON.stringify(
              doc
            )})'>Edit</a><a class='btn btn-sm btn-danger fw-light rounded-0' onclick='changeSection(\"delete-tracking\",${JSON.stringify(
              doc
            )})'>Archive</a>`;
            if (doc.customer) {
              doc.customer_name = doc.customer.customer_name;
            } else {
              doc.customer_name = "-";
            }
            if (doc.shippedBy) {
              doc.shippedBy_hub_name = doc.shippedBy.hub_name;
            } else {
              doc.shippedBy_hub_name = "-";
            }
            if (doc.shippedTo) {
              doc.shippedTo_hub_name = doc.shippedTo.hub_name;
            } else {
              doc.shippedTo_hub_name = "-";
            }
            if (doc.product) {
              doc.product_name = doc.product.product_name;
            } else {
              doc.product_name = "-";
            }
            if (doc.weight) {
              doc.weight += " kg";
            } else {
              doc.weight = "-";
            }
            if (doc.costs) {
              doc.costs += " &#x20AC;";
            } else {
              doc.costs = "-";
            }
            const status = doc.delivery_status;
            let color;
            switch (status) {
              case "Transit":
                color = "primary";
                break;
              case "Delivered":
                color = "success";
                break;
              case "Exception":
                color = "danger";
                break;
              default:
                color = "secondary";
            }
            doc.delivery_status = `<div class="badge text-bg-${color}">${status}</div>`;
          });
          return response.documents;
        },
      },
      columns: [
        { data: "tracking_number" },
        { data: "delivery_status" },
        { data: "customer_name", orderable: false, searchable: false },
        { data: "shippedBy_hub_name", orderable: false, searchable: false },
        { data: "shippedTo_hub_name", orderable: false, searchable: false },
        { data: "product_name", orderable: false, searchable: false },
        { data: "weight", orderable: false, searchable: false },
        { data: "costs", orderable: false, searchable: false },
        { data: "options", orderable: false, searchable: false },
      ],
      responsive: true,
    });
    $("div.dataTables_filter input").addClass("form-control");
    $(".dt-button").removeClass("dt-button");
  }
  async function addTracking() {
    const tracking_number = document.getElementById("inputTrackingNumber");
    const courier = document.getElementById("inputCourier");
    const customer = document.getElementById("inputCustomer");
    const shippedBy = document.getElementById("inputShippedBy");
    const shippedTo = document.getElementById("inputShippedTo");
    //const product = document.getElementById('inputProduct')
    const tracking_weight = document.getElementById("inputTrackingWeight");
    const tracking_costs = document.getElementById("inputTrackingCosts");
    const tracking_success = document.getElementById("tracking-success");
    const tracking_failed = document.getElementById("tracking-failed");
    const tracking_spinner = document.getElementById("tracking-spinner");
    const tracking_button = document.getElementById("tracking-button");

    // reset form
    clearForm("add-tracking");

    // form validation
    if (!tracking_number.value) return tracking_number.classList.add("is-invalid");
    if (tracking_weight.value && tracking_weight.value < 0) return tracking_weight.classList.add("is-invalid");
    if (tracking_costs.value && tracking_costs.value < 0) return tracking_costs.classList.add("is-invalid");

    const key = await getItem("key");
    if (!key) throw Error("Could not get key from storage");

    // fetch
    tracking_spinner.hidden = false;
    tracking_button.disabled = true;
    const body = {
      tracking_number: tracking_number.value,
      courier_code: courier.value,
      weight: Number(tracking_weight.value) || null,
      costs: Number(tracking_costs.value) || null,
      customer: Number(customer.value) || null,
      shippedBy: Number(shippedBy.value) || null,
      shippedTo: Number(shippedTo.value) || null,
      //product: Number(product.value) || null
    };
    try {
      const url = "https://api.globalpackagetracker.com/shipment/create";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          key: key,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (data.status == 201) {
        tracking_number.value = "";
        tracking_weight.value = "";
        tracking_costs.value = "";
        tracking_success.hidden = false;
      } else {
        tracking_failed.textContent = `Failed to create tracking: ${data.message}`;
        tracking_failed.hidden = false;
      }
    } catch (e) {
      tracking_failed.textContent = `Failed to create tracking: ${e.message}`;
      tracking_failed.hidden = false;
    } finally {
      tracking_spinner.hidden = true;
      tracking_button.disabled = false;
      document.getElementById("add-tracking").scrollTo(0, document.getElementById("add-tracking").scrollHeight);
    }
  }
  async function updateTracking() {
    const tracking_number = document.getElementById("inputTrackingNumber1");
    const courier = document.getElementById("inputCourier1");
    const customer = document.getElementById("inputCustomer1");
    const shippedBy = document.getElementById("inputShippedBy1");
    const shippedTo = document.getElementById("inputShippedTo1");
    //const product = document.getElementById('inputProduct1')
    const tracking_weight = document.getElementById("inputTrackingWeight1");
    const tracking_costs = document.getElementById("inputTrackingCosts1");
    const tracking_success = document.getElementById("tracking-success1");
    const tracking_failed = document.getElementById("tracking-failed1");
    const tracking_spinner = document.getElementById("tracking-spinner1");
    const tracking_button = document.getElementById("tracking-button1");

    // reset form
    clearForm("edit-tracking");

    // form validation
    if (!tracking_number.value) return tracking_number.classList.add("is-invalid");
    if (tracking_weight.value && tracking_weight.value < 0) return tracking_weight.classList.add("is-invalid");
    if (tracking_costs.value && tracking_costs.value < 0) return tracking_costs.classList.add("is-invalid");

    const key = await getItem("key");
    if (!key) throw Error("Could not get key from storage");

    // fetch
    tracking_spinner.hidden = false;
    tracking_button.disabled = true;
    const body = {
      tracking_number: tracking_number.value,
      courier_code: courier.value,
      weight: Number(tracking_weight.value) || null,
      costs: Number(tracking_costs.value) || null,
      customer: Number(customer.value) || null,
      shippedBy: Number(shippedBy.value) || null,
      shippedTo: Number(shippedTo.value) || null,
      //product: Number(product.value) || null
    };
    try {
      const url = "https://api.globalpackagetracker.com/shipment/update";
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          key: key,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (data.status == 200) {
        // prepare body for updating indexedDb
        if (body.customer) {
          body.customer = {
            customer_id: Number(customer.value),
            customer_name: customer.selectedOptions[0].textContent,
          };
        }
        if (body.shippedBy) {
          body.shippedBy = {
            hub_id: Number(shippedBy.value),
            hub_name: shippedBy.selectedOptions[0].textContent,
          };
        }
        if (body.shippedTo) {
          body.shippedTo = {
            hub_id: Number(shippedTo.value),
            hub_name: shippedTo.selectedOptions[0].textContent,
          };
        }
        if (body.product) {
          body.product = {
            product_id: Number(product.value),
            product_name: product.selectedOptions[0].textContent,
          };
        }
        tracking_success.hidden = false;
      } else {
        tracking_failed.textContent = `Failed to update tracking: ${data.message}`;
        tracking_failed.hidden = false;
      }
    } catch (e) {
      tracking_failed.textContent = `Failed to update tracking: ${e.message}`;
      tracking_failed.hidden = false;
    } finally {
      tracking_spinner.hidden = true;
      tracking_button.disabled = false;
      document.getElementById("edit-tracking").scrollTo(0, document.getElementById("edit-tracking").scrollHeight);
    }
  }
  async function deleteTracking() {
    const tracking_number = document.getElementById("tracking-number-delete");
    const courier_code = document.getElementById("courier-code-delete");
    const tracking_success = document.getElementById("tracking-success2");
    const tracking_failed = document.getElementById("tracking-failed2");
    const tracking_button = document.getElementById("tracking-button2");
    const tracking_spinner = document.getElementById("tracking-spinner2");

    const key = await getItem("key");
    if (!key) throw Error("Could not get key from storage");

    tracking_spinner.hidden = false;
    tracking_button.disabled = true;
    const body = {
      tracking_number: tracking_number.innerText,
      courier_code: courier_code.innerText,
    };
    console.log(body);
    try {
      const url = "https://api.globalpackagetracker.com/shipment/archive";
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          key: key,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (data.status == 200) {
        tracking_success.hidden = false;
      } else {
        tracking_failed.textContent = `Failed to archive tracking: ${data.message}`;
        tracking_failed.hidden = false;
      }
    } catch (e) {
      tracking_failed.textContent = `Failed to archive tracking: ${e.message}`;
      tracking_failed.hidden = false;
    } finally {
      tracking_spinner.hidden = true;
    }
  }
  function getRanges() {
    // ranges for daterangepicker
    const ranges = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "Last 90 Days": [moment().subtract(89, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
    };
    return ranges;
  }
  async function initTrackingsDaterange() {
    if ($(`#trackings-daterange`).data("daterangepicker")) return;
    var daterangeItem = await getItem("trackings-daterange");
    var daterange;
    if (daterangeItem) {
      daterange = JSON.parse(daterangeItem);
    } else {
      daterange = {
        start: moment().subtract(6, "days"),
        end: moment(),
      };
      var daterangeSaved = setItem("trackings-daterange", JSON.stringify(daterange));
      if (!daterangeSaved) throw Error("Could not save daterange in storage");
    }
    $("#trackings-daterange").daterangepicker(
      {
        showDropdowns: false,
        maxSpan: {
          days: 90,
        },
        ranges: getRanges(),
        showCustomRangeLabel: false,
        startDate: moment(daterange.start).format("MM/DD/YYYY"),
        endDate: moment(daterange.end).format("MM/DD/YYYY"),
        minDate: "01/01/2020",
        maxDate: moment(),
        opens: "center",
      },
      async function (start, end, label) {
        var daterangeSaved = await setItem("trackings-daterange", JSON.stringify({ start, end }));
        if (!daterangeSaved) throw Error("Could not save daterange in storage");
        refreshDataTable("trackings-table");
      }
    );
    // update daterangepickers when opened to put the current date in case it changed
    $(`#trackings-daterange`).on("show.daterangepicker", function () {
      console.log("update daterangepicker view");
      $(`#trackings-daterange`).data("daterangepicker").ranges = getRanges();
      $(`#trackings-daterange`).data("daterangepicker").maxDate = moment();
      $(`#trackings-daterange`).data("daterangepicker").updateView();
    });
  }

  async function loadProducts() {
    if ($.fn.DataTable.isDataTable("#products-table")) {
      $("#products-table").DataTable().ajax.reload();
      return;
    }

    const key = await getItem("key");
    if (!key) throw Error("Could not get key from storage");

    const table = $("#products-table").DataTable({
      dom: '<"row gx-2"<"col"<"float-start"f>><"col-auto"B>>trip',
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search",
      },
      buttons: [
        {
          text: '<i class="bi bi-plus-lg bi-bold me-1"></i>Add product',
          className: "btn btn-dark",
          action: function (e, dt, node, config) {
            changeSection("add-product");
          },
        },
      ],
      ajax: {
        url: "https://api.globalpackagetracker.com/product/get",
        type: "GET",
        headers: {
          key,
        },
        dataSrc: function (response) {
          if (response.status !== 200) return [];
          response.documents.forEach((doc) => {
            doc.options = `<a class='btn btn-sm btn-warning me-3 fw-light rounded-0' onclick='changeSection(\"edit-product\",${JSON.stringify(
              doc
            )})'>Edit</a><a class='btn btn-sm btn-danger fw-light rounded-0' onclick='changeSection(\"delete-product\",${JSON.stringify(
              doc
            )})'>Archive</a>`;
            if (doc.product_weight) {
              doc.product_weight += " kg";
            } else {
              doc.product_weight = "-";
            }
            if (doc.product_costs) {
              doc.product_costs += " &#x20AC;";
            } else {
              doc.product_costs = "-";
            }
          });
          return response.documents;
        },
      },
      columns: [
        { data: "product_id" },
        { data: "product_name" },
        { data: "product_weight", orderable: false, searchable: false },
        { data: "product_costs", orderable: false, searchable: false },
        { data: "options", orderable: false, searchable: false },
      ],
      responsive: true,
    });
    $("div.dataTables_filter input").addClass("form-control");
    $(".dt-button").removeClass("dt-button");
  }
  async function addProduct() {
    const product_name = document.getElementById("inputProductName");
    const product_weight = document.getElementById("inputProductWeight");
    const product_costs = document.getElementById("inputProductCosts");
    const product_success = document.getElementById("product-success");
    const product_failed = document.getElementById("product-failed");
    const product_spinner = document.getElementById("product-spinner");
    const product_button = document.getElementById("product-button");

    // reset form
    clearForm("add-product");

    // form validation
    if (!product_name.value) return product_name.classList.add("is-invalid");
    if (product_weight.value && product_weight.value < 0) return product_weight.classList.add("is-invalid");
    if (product_costs.value && product_costs.value < 0) return product_costs.classList.add("is-invalid");

    const key = await getItem("key");
    if (!key) throw Error("Could not get key from storage");

    // fetch
    document.getElementById("product-spinner").hidden = false;
    document.getElementById("product-button").disabled = true;
    const body = {
      product_name: product_name.value,
      product_weight: Number(product_weight.value) || null,
      product_costs: Number(product_costs.value) || null,
    };
    try {
      const url = "https://api.globalpackagetracker.com/product/create";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          key: key,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (data.status == 201) {
        product_name.value = "";
        product_weight.value = "";
        product_costs.value = "";
        product_success.hidden = false;
      } else {
        product_failed.textContent = `Failed to create product: ${data.message}`;
        product_failed.hidden = false;
      }
    } catch (e) {
      product_failed.textContent = `Failed to create product: ${e.message}`;
      product_failed.hidden = false;
    } finally {
      product_spinner.hidden = true;
      product_button.disabled = false;
    }
  }
  async function updateProduct() {
    const product_id = document.getElementById("inputProductId");
    const product_name = document.getElementById("inputProductName1");
    const product_weight = document.getElementById("inputProductWeight1");
    const product_costs = document.getElementById("inputProductCosts1");
    const product_success = document.getElementById("product-success1");
    const product_failed = document.getElementById("product-failed1");
    const product_button = document.getElementById("product-button1");
    const product_spinner = document.getElementById("product-spinner1");

    // reset form
    clearForm("edit-product");

    // form validation
    if (!product_name.value) return product_name.classList.add("is-invalid");
    if (product_weight.value && product_weight.value < 0) return product_weight.classList.add("is-invalid");
    if (product_costs.value && product_costs.value < 0) return product_costs.classList.add("is-invalid");

    const key = await getItem("key");
    if (!key) throw Error("Could not get key from storage");

    // fetch
    product_spinner.hidden = false;
    product_button.disabled = true;
    const body = {
      product_id: Number(product_id.value),
      product_name: product_name.value,
      product_weight: Number(product_weight.value) || null,
      product_costs: Number(product_costs.value) || null,
    };
    try {
      const url = "https://api.globalpackagetracker.com/product/update";
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          key: key,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (data.status == 200) {
        product_success.hidden = false;
      } else {
        product_failed.textContent = `Failed to update product: ${data.message}`;
        product_failed.hidden = false;
      }
    } catch (e) {
      product_failed.textContent = `Failed to update product: ${e.message}`;
      product_failed.hidden = false;
    } finally {
      product_spinner.hidden = true;
      product_button.disabled = false;
    }
  }
  async function deleteProduct() {
    const product_id = document.getElementById("product-id-delete");
    const product_success = document.getElementById("product-success2");
    const product_failed = document.getElementById("product-failed2");
    const product_button = document.getElementById("product-button2");
    const product_spinner = document.getElementById("product-spinner2");

    const key = await getItem("key");
    if (!key) throw Error("Could not get key from storage");

    product_spinner.hidden = false;
    product_button.disabled = true;
    const body = {
      product_id: Number(product_id.innerText),
    };
    try {
      const url = "https://api.globalpackagetracker.com/product/archive";
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          key: key,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (data.status == 200) {
        product_success.hidden = false;
      } else {
        product_failed.textContent = `Failed to archive product: ${data.message}`;
        product_failed.hidden = false;
      }
    } catch (e) {
      product_failed.textContent = `Failed to archive product: ${e.message}`;
      product_failed.hidden = false;
    } finally {
      product_spinner.hidden = true;
    }
  }

  async function loadCustomers() {
    if ($.fn.DataTable.isDataTable("#customers-table")) {
      $("#customers-table").DataTable().ajax.reload();
      return;
    }

    const key = await getItem("key");
    if (!key) throw Error("Could not get key from storage");

    const table = $("#customers-table").DataTable({
      dom: '<"row gx-2"<"col"<"float-start"f>><"col-auto"B>>trip',
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search",
      },
      buttons: [
        {
          text: '<i class="bi bi-plus-lg bi-bold me-1"></i>Add customer',
          className: "btn btn-dark",
          action: function (e, dt, node, config) {
            changeSection("add-customer");
          },
        },
      ],
      ajax: {
        url: "https://api.globalpackagetracker.com/customer/get",
        type: "GET",
        headers: {
          key: key,
        },
        dataSrc: function (response) {
          if (response.status !== 200) return [];
          response.documents.forEach((doc) => {
            doc.options = `<a class='btn btn-sm btn-warning me-3 fw-light rounded-0' onclick='changeSection(\"edit-customer\",${JSON.stringify(
              doc
            )})'>Edit</a><a class='btn btn-sm btn-danger fw-light rounded-0' onclick='changeSection(\"delete-customer\",${JSON.stringify(
              doc
            )})'>Archive</a>`;
            if (!doc.customer_email) doc.customer_email = "-";
            if (!doc.customer_phone) doc.customer_phone = "-";
          });
          return response.documents;
        },
      },
      columns: [
        { data: "customer_id" },
        { data: "customer_name" },
        { data: "customer_email", orderable: false, searchable: false },
        { data: "customer_phone", orderable: false, searchable: false },
        { data: "options", orderable: false, searchable: false },
      ],
      responsive: true,
    });
    $("div.dataTables_filter input").addClass("form-control");
    $(".dt-button").removeClass("dt-button");
  }
  async function addCustomer() {
    const customer_name = document.getElementById("inputCustomerName");
    const customer_email = document.getElementById("inputCustomerEmail");
    const customer_phone = document.getElementById("inputCustomerPhone");
    const customer_success = document.getElementById("customer-success");
    const customer_failed = document.getElementById("customer-failed");
    const customer_spinner = document.getElementById("customer-spinner");
    const customer_button = document.getElementById("customer-button");

    // reset form
    clearForm("add-customer");

    // form validation
    if (!customer_name.value) return customer_name.classList.add("is-invalid");

    const key = await getItem("key");
    if (!key) throw Error("Could not get key from storage");

    // fetch
    document.getElementById("customer-spinner").hidden = false;
    document.getElementById("customer-button").disabled = true;
    const body = {
      customer_name: customer_name.value,
      customer_email: customer_email.value || null,
      customer_phone: customer_phone.value || null,
    };
    try {
      const url = "https://api.globalpackagetracker.com/customer/create";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          key: key,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (data.status == 201) {
        customer_name.value = "";
        customer_email.value = "";
        customer_phone.value = "";
        customer_success.hidden = false;
      } else {
        if (data.message.includes("Email is invalid")) {
          customer_email.classList.add("is-invalid");
          customer_failed.textContent = `Failed to create customer: Email is invalid`;
        } else {
          customer_failed.textContent = `Failed to create customer: ${data.message}`;
        }
        customer_failed.hidden = false;
      }
    } catch (e) {
      customer_failed.textContent = `Failed to create customer: ${e.message}`;
      customer_failed.hidden = false;
    } finally {
      customer_spinner.hidden = true;
      customer_button.disabled = false;
    }
  }
  async function updateCustomer() {
    const customer_id = document.getElementById("inputCustomerId");
    const customer_name = document.getElementById("inputCustomerName1");
    const customer_email = document.getElementById("inputCustomerEmail1");
    const customer_phone = document.getElementById("inputCustomerPhone1");
    const customer_success = document.getElementById("customer-success1");
    const customer_failed = document.getElementById("customer-failed1");
    const customer_button = document.getElementById("customer-button1");
    const customer_spinner = document.getElementById("customer-spinner1");

    // reset form
    clearForm("edit-customer");

    // form validation
    if (!customer_name.value) return customer_name.classList.add("is-invalid");

    const key = await getItem("key");
    if (!key) throw Error("Could not get key from storage");

    // fetch
    customer_spinner.hidden = false;
    customer_button.disabled = true;
    const body = {
      customer_id: Number(customer_id.value),
      customer_name: customer_name.value,
      customer_email: customer_email.value || null,
      customer_phone: customer_phone.value || null,
    };
    try {
      const url = "https://api.globalpackagetracker.com/customer/update";
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          key: key,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (data.status == 200) {
        customer_success.hidden = false;
      } else {
        if (data.message.includes("Email is invalid")) {
          customer_email.classList.add("is-invalid");
          customer_failed.textContent = `Failed to create customer: Email is invalid`;
        } else {
          customer_failed.textContent = `Failed to create customer: ${data.message}`;
        }
        customer_failed.hidden = false;
      }
    } catch (e) {
      customer_failed.textContent = `Failed to update customer: ${e.message}`;
      customer_failed.hidden = false;
    } finally {
      customer_spinner.hidden = true;
      customer_button.disabled = false;
    }
  }
  async function deleteCustomer() {
    const customer_id = document.getElementById("customer-id-delete");
    const customer_success = document.getElementById("customer-success2");
    const customer_failed = document.getElementById("customer-failed2");
    const customer_button = document.getElementById("customer-button2");
    const customer_spinner = document.getElementById("customer-spinner2");

    const key = await getItem("key");
    if (!key) throw Error("Could not get key from storage");

    customer_spinner.hidden = false;
    customer_button.disabled = true;
    const body = {
      customer_id: Number(customer_id.innerText),
    };
    try {
      const url = "https://api.globalpackagetracker.com/customer/archive";
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          key: key,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (data.status == 200) {
        customer_success.hidden = false;
      } else {
        customer_failed.textContent = `Failed to archive customer: ${data.message}`;
        customer_failed.hidden = false;
      }
    } catch (e) {
      customer_failed.textContent = `Failed to archive customer: ${e.message}`;
      customer_failed.hidden = false;
    } finally {
      customer_spinner.hidden = true;
    }
  }

  async function loadHubs() {
    if ($.fn.DataTable.isDataTable("#hubs-table")) {
      $("#hubs-table").DataTable().ajax.reload();
      return;
    }

    const key = await getItem("key");
    if (!key) throw Error("Could not get key from storage");

    const table = $("#hubs-table").DataTable({
      dom: '<"row gx-2"<"col"<"float-start"f>><"col-auto"B>>trip',
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search",
      },
      buttons: [
        {
          text: '<i class="bi bi-plus-lg bi-bold me-1"></i>Add hub',
          className: "btn btn-dark",
          action: function (e, dt, node, config) {
            changeSection("add-hub");
          },
        },
      ],
      ajax: {
        url: "https://api.globalpackagetracker.com/hub/get",
        type: "GET",
        headers: {
          key: key,
        },
        dataSrc: function (response) {
          if (response.status !== 200) return [];
          response.documents.forEach((doc) => {
            doc.options = `<a class='btn btn-sm btn-warning me-3 fw-light rounded-0' onclick='changeSection(\"edit-hub\",${JSON.stringify(
              doc
            )})'>Edit</a><a class='btn btn-sm btn-danger fw-light rounded-0' onclick='changeSection(\"delete-hub\",${JSON.stringify(
              doc
            )})'>Archive</a>`;
            if (!doc.hub_owner) {
              doc.hub_owner_name = "My hub";
            } else {
              doc.hub_owner_name = doc.hub_owner.customer_name;
            }
            if (!doc.hub_address) doc.hub_address = "-";
            if (!doc.hub_email) doc.hub_email = "-";
            if (!doc.hub_phone) doc.hub_phone = "-";
          });
          return response.documents;
        },
      },
      columns: [
        { data: "hub_id" },
        { data: "hub_name" },
        { data: "hub_owner_name", orderable: false, searchable: false },
        { data: "hub_address", orderable: false, searchable: false },
        { data: "hub_email", orderable: false, searchable: false },
        { data: "hub_phone", orderable: false, searchable: false },
        { data: "options", orderable: false, searchable: false },
      ],
      responsive: true,
    });
    $("div.dataTables_filter input").addClass("form-control");
    $(".dt-button").removeClass("dt-button");
  }
  async function addHub() {
    const hub_name = document.getElementById("inputHubName");
    const hub_owner = document.getElementById("inputHubOwner");
    const hub_address = document.getElementById("inputHubAddress");
    const hub_email = document.getElementById("inputHubEmail");
    const hub_phone = document.getElementById("inputHubPhone");
    const hub_success = document.getElementById("hub-success");
    const hub_failed = document.getElementById("hub-failed");
    const hub_spinner = document.getElementById("hub-spinner");
    const hub_button = document.getElementById("hub-button");

    // reset form
    clearForm("add-hub");

    // form validation
    if (!hub_name.value) return hub_name.classList.add("is-invalid");

    const key = await getItem("key");
    if (!key) throw Error("Could not get key from storage");

    // fetch
    document.getElementById("hub-spinner").hidden = false;
    document.getElementById("hub-button").disabled = true;
    const body = {
      hub_name: hub_name.value,
      hub_address: hub_address.value || null,
      hub_email: hub_email.value || null,
      hub_phone: hub_phone.value || null,
    };
    if (hub_owner.value !== "null") body.hub_owner = hub_owner.value || null;
    try {
      const url = "https://api.globalpackagetracker.com/hub/create";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          key: key,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (data.status == 201) {
        hub_name.value = "";
        enableDselect("inputHubOwner", "customer-as-hub-owner");
        hub_address.value = "";
        hub_email.value = "";
        hub_phone.value = "";
        hub_success.hidden = false;
      } else {
        if (data.message.includes("Email is invalid")) {
          hub_email.classList.add("is-invalid");
          hub_failed.textContent = `Failed to create hub: Email is invalid`;
        } else {
          hub_failed.textContent = `Failed to create hub: ${data.message}`;
        }
        hub_failed.hidden = false;
      }
    } catch (e) {
      hub_failed.textContent = `Failed to create hub: ${e.message}`;
      hub_failed.hidden = false;
    } finally {
      hub_spinner.hidden = true;
      hub_button.disabled = false;
    }
  }
  async function updateHub() {
    const hub_id = document.getElementById("inputHubId");
    const hub_name = document.getElementById("inputHubName1");
    const hub_owner = document.getElementById("inputHubOwner1");
    const hub_address = document.getElementById("inputHubAddress1");
    const hub_email = document.getElementById("inputHubEmail1");
    const hub_phone = document.getElementById("inputHubPhone1");
    const hub_success = document.getElementById("hub-success1");
    const hub_failed = document.getElementById("hub-failed1");
    const hub_button = document.getElementById("hub-button1");
    const hub_spinner = document.getElementById("hub-spinner1");

    // reset form
    clearForm("edit-hub");

    // form validation
    if (!hub_name.value) return hub_name.classList.add("is-invalid");

    const key = await getItem("key");
    if (!key) throw Error("Could not get key from storage");

    // fetch
    hub_spinner.hidden = false;
    hub_button.disabled = true;
    const body = {
      hub_id: Number(hub_id.value),
      hub_name: hub_name.value,
      hub_address: hub_address.value || null,
      hub_email: hub_email.value || null,
      hub_phone: hub_phone.value || null,
    };
    if (hub_owner.value === "null") {
      body.hub_owner = null;
    } else {
      body.hub_owner = hub_owner.value;
    }
    try {
      const url = "https://api.globalpackagetracker.com/hub/update";
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          key: key,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (data.status == 200) {
        hub_success.hidden = false;
      } else {
        if (data.message.includes("Email is invalid")) {
          hub_email.classList.add("is-invalid");
          hub_failed.textContent = `Failed to create hub: Email is invalid`;
        } else {
          hub_failed.textContent = `Failed to create hub: ${data.message}`;
        }
        hub_failed.hidden = false;
      }
    } catch (e) {
      hub_failed.textContent = `Failed to update hub: ${e.message}`;
      hub_failed.hidden = false;
    } finally {
      hub_spinner.hidden = true;
      hub_button.disabled = false;
    }
  }
  async function deleteHub() {
    const hub_id = document.getElementById("hub-id-delete");
    const hub_success = document.getElementById("hub-success2");
    const hub_failed = document.getElementById("hub-failed2");
    const hub_button = document.getElementById("hub-button2");
    const hub_spinner = document.getElementById("hub-spinner2");

    const key = await getItem("key");
    if (!key) throw Error("Could not get key from storage");

    hub_spinner.hidden = false;
    hub_button.disabled = true;
    const body = {
      hub_id: Number(hub_id.innerText),
    };
    try {
      const url = "https://api.globalpackagetracker.com/hub/archive";
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          key: key,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (data.status == 200) {
        hub_success.hidden = false;
      } else {
        hub_failed.textContent = `Failed to archive hub: ${data.message}`;
        hub_failed.hidden = false;
      }
    } catch (e) {
      hub_failed.textContent = `Failed to archive hub: ${e.message}`;
      hub_failed.hidden = false;
    } finally {
      hub_spinner.hidden = true;
    }
  }

  async function loadCouriers() {
    if ($.fn.DataTable.isDataTable("#couriers-table")) {
      $("#couriers-table").DataTable().ajax.reload();
      return;
    }

    const key = await getItem("key");
    if (!key) throw Error("Could not get key from storage");

    const table = $("#couriers-table").DataTable({
      dom: '<"row gx-2"<"col"<"float-start w-100"f>>>trip',
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search",
      },
      ajax: {
        url: "https://api.globalpackagetracker.com/courier/get",
        type: "GET",
        headers: {
          key: key,
        },
        dataSrc: function (response) {
          if (response.status !== 200) return [];
          return response.documents;
        },
      },
      columns: [{ data: "courierName" }, { data: "courierCode" }],
      responsive: true,
    });
    $("div.dataTables_filter input").addClass("form-control");
  }

  async function enableDselect(selectElem, dataSrc, selectedId = null) {
    const select = document.getElementById(selectElem);

    const options_noclear = {
      search: true,
      size: "md",
      maxHeight: "100px",
    };
    const options_clearable = {
      search: true,
      size: "md",
      maxHeight: "100px",
      clearable: true,
    };

    var url = "https://api.globalpackagetracker.com/";
    switch (dataSrc) {
      case "customer-as-hub-owner":
      case "customers":
        url += "customer/get";
        break;
      case "own-hubs":
        url += "hub/get?own_hubs=true";
        break;
      case "customer-hubs":
        url += "hub/get?own_hubs=false";
        break;
      case "products":
        url += "product/get";
        break;
      case "couriers":
        url += "courier/get";
        break;
    }

    const key = await getItem("key");
    if (!key) throw Error("Could not get key from storage");

    let documents;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          key: key,
        },
      });
      const data = await response.json();
      if (data.status == 200) {
        documents = data.documents;
      } else {
        documents = [];
      }
    } catch (e) {
      documents = [];
    }

    let innerElements;
    switch (dataSrc) {
      case "customer-as-hub-owner":
        innerElements += '<option value="null">This is my hub</option>';
        documents.forEach((doc) => {
          if (selectedId == doc.customer_id) {
            innerElements += `<option value="${doc.customer_id}" selected>${doc.customer_name}</option>`;
          } else {
            innerElements += `<option value="${doc.customer_id}">${doc.customer_name}</option>`;
          }
        });
        select.innerHTML = innerElements;
        dselect(select, options_noclear);
        break;
      case "customers":
        innerElements += '<option value="">Choose</option>';
        documents.forEach((doc) => {
          if (selectedId == doc.customer_id) {
            innerElements += `<option value="${doc.customer_id}" selected>${doc.customer_name}</option>`;
          } else {
            innerElements += `<option value="${doc.customer_id}">${doc.customer_name}</option>`;
          }
        });
        select.innerHTML = innerElements;
        dselect(select, options_clearable);
        break;
      case "own-hubs":
      case "customer-hubs":
        innerElements += '<option value="">Choose</option>';
        documents.forEach((doc) => {
          if (selectedId == doc.hub_id) {
            innerElements += `<option value="${doc.hub_id}" selected>${doc.hub_name}</option>`;
          } else {
            innerElements += `<option value="${doc.hub_id}">${doc.hub_name}</option>`;
          }
        });
        select.innerHTML = innerElements;
        dselect(select, options_clearable);
        break;
      case "products":
        innerElements += '<option value="">Choose</option>';
        documents.forEach((doc) => {
          if (selectedId == doc.product_id) {
            innerElements += `<option value="${doc.product_id}" selected>${doc.product_name}</option>`;
          } else {
            innerElements += `<option value="${doc.product_id}">${doc.product_name}</option>`;
          }
        });
        select.innerHTML = innerElements;
        dselect(select, options_clearable);
        break;
      case "couriers":
        documents.forEach((doc) => {
          if (selectedId == doc.courierCode) {
            innerElements += `<option value="${doc.courierCode}" selected>${doc.courierName}</option>`;
          } else {
            innerElements += `<option value="${doc.courierCode}">${doc.courierName}</option>`;
          }
        });
        select.innerHTML = innerElements;
        dselect(select, options_noclear);
        break;
    }
  }

  async function saveSettings() {
    const success = document.getElementById("settings-success");
    const button = document.getElementById("save-settings-button");
    const spinner = document.getElementById("settings-spinner");

    success.hidden = true;
    button.disabled = true;
    spinner.hidden = false;

    const dateFormat = document.getElementById("inputDateFormat").value;
    const dateFormatSaved = await setItem("date_format", dateFormat);
    if (!dateFormatSaved) throw Error("Could not save date format in storage");

    const weightUnit = document.getElementById("inputWeightUnit").value;
    const weightUnitSaved = await setItem("weight_unit", weightUnit);
    if (!weightUnitSaved) throw Error("Could not save weight unit in storage");

    setTimeout(() => {
      spinner.hidden = true;
      button.disabled = false;
      success.hidden = false;
    }, 100);
  }

  async function refreshDataTable(id, button) {
    var refreshIcon, spinner, wrapper;
    if (button) {
      button[0].disabled = true;
      wrapper = button[0].children[0];

      spinner = document.createElement("div");
      spinner.classList.add("spinner-border", "text-light", "spinner-border-sm", "ms-1");

      refreshIcon = wrapper.children[1];
      wrapper.removeChild(refreshIcon);
      wrapper.appendChild(spinner);
    }

    const table = id.split("-")[0];

    const daterangeItem = await getItem(table + "-daterange");
    if (!daterangeItem) throw Error("Could not get daterange from storage");
    const daterange = JSON.parse(daterangeItem);

    let dtable = $("#" + id).DataTable();
    // Overwrite the data function with the new range
    dtable.settings()[0].ajax.data = function (data) {
      data.start = new Date(daterange.start).getTime();
      data.end = new Date(daterange.end).getTime();
    };
    $("#" + id)
      .DataTable()
      .ajax.reload(function () {
        if (button) {
          wrapper.removeChild(spinner);
          wrapper.appendChild(refreshIcon);
          button[0].disabled = false;
        }
      });
  }

  async function setItem(key, value) {
    try {
      await OfficeRuntime.storage.setItem(key, value);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async function getItem(key) {
    try {
      const value = await OfficeRuntime.storage.getItem(key);
      return value;
    } catch (error) {
      console.log("Error retrieving data", error);
      return false;
    }
  }

  async function removeItem(key) {
    try {
      await OfficeRuntime.storage.removeItem(key);
      return true;
    } catch (error) {
      console.log("Error retrieving data", error);
      return false;
    }
  }
});

//export async function run() {}
